<template>
  <div class="LockOut container has-text-centered">
    <heading color="white">
      {{ $t('pages.lock_out.title') }}
    </heading>
    <router-link class="button is-white is-outlined" :to="{ name: 'login', query: $route.query }">
      {{ $t('pages.lock_out.get_back_to_app') }}
    </router-link>
  </div>
</template>

<script>
import store from '@/store'

/**
 * @module LockOut
 */
export default {
  name: 'LockOut',
  beforeRouteEnter (to, from, next) {
    store.dispatch('logoutGlobally').catch((err) => {
      console.log(err)
    })
    next()
  }
}
</script>
